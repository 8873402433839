import * as React from "react";
import styled, { ThemeProvider } from "styled-components";
import { lightTheme } from "../components/theme";
import MetaComponent from "../components/MetaComponent";
import HeroWhitepaper from "../components/whitepaper/heroWhitepaper";
import Menu from "../components/Menu";
import Footer from "../components/Footer";
import PaperContent from "../components/whitepaper/paperContent";
import { useTranslation } from "react-i18next";
import heroBackgound from "../images/whitepaper_newbg.jpg";
import heroBackgoundMobile from "../images/whitepaper_newbg_mobile.jpg";

//Economic Model Update

import CHupdate from "../docs/Economic Model Update - Chinese.pdf";
import ARupdate from "../docs/Economic Model Update - Arabic.pdf";
import JPupdate from "../docs/Economic Model Update - Japanese.pdf";
import KRupdate from "../docs/Economic Model Update - Korean.pdf";
import RUupdate from "../docs/Economic Model Update - Russian.pdf";
import SPupdate from "../docs/Economic Model Update - Spanish.pdf";
import ENupdate from "../docs/Economic Model Update - English.pdf";
import FRupdate from "../docs/Economic Model Update - French.pdf";
import DEupdate from "../docs/Economic Model Update - German.pdf";
import ITupdate from "../docs/Economic Model Update - Italian.pdf";
import VIupdate from "../docs/Economic Model Update - Vietnam.pdf";
import GKupdate from "../docs/Economic Model Update - Greek.pdf";
import PLupdate from "../docs/Economic Model Update - Polish.pdf";
import PTupdate from "../docs/Economic Model Update - Portuguese.pdf";

//Economic Model Whitepaper
import ARwhitepaper from "../docs/Economic Model Whitepaper - Arabic.pdf";
import CHwhitepaper from "../docs/Economic Model Whitepaper - Chinese.pdf";
import ENwhitepaper from "../docs/Economic Model Whitepaper - English.pdf";
import JPwhitepaper from "../docs/Economic Model Whitepaper - Japanese.pdf";
import KRwhitepaper from "../docs/Economic Model Whitepaper - Korean.pdf";
import RUwhitepaper from "../docs/Economic Model Whitepaper - Russian.pdf";
import SPwhitepaper from "../docs/Economic Model Whitepaper - Spanish.pdf";
import FRwhitepaper from "../docs/Economic Model Whitepaper - French.pdf";
import DEwhitepaper from "../docs/Economic Model Whitepaper - German.pdf";
import TRwhitepaper from "../docs/Economic Model Whitepaper - Turkish.pdf";
import ITwhitepaper from "../docs/Economic Model Whitepaper - Italian.pdf";
import VIwhitepaper from "../docs/Economic Model Whitepaper - Vietnam.pdf";
import GKwhitepaper from "../docs/Economic Model Whitepaper - Greek.pdf";
import PLwhitepaper from "../docs/Economic Model Whitepaper - Polish.pdf";
import PTwhitepaper from "../docs/Economic Model Whitepaper - Portuguese.pdf";

//KeyFi Original Litepaper
import ARlitepaper from "../docs/Litepaper - Arabic.pdf";
import CHlitepaper from "../docs/Litepaper - Chinese.pdf";
import ENlitepaper from "../docs/Litepaper - English.pdf";
import JPlitepaper from "../docs/Litepaper - Japanese.pdf";
import KRlitepaper from "../docs/Litepaper - Korean.pdf";
import RUlitepaper from "../docs/Litepaper - Russian.pdf";
import SPlitepaper from "../docs/Litepaper - Spanish.pdf";
import FRlitepaper from "../docs/Litepaper - French.pdf";
import DElitepaper from "../docs/Litepaper - German.pdf";
import TRlitepaper from "../docs/Litepaper - Turkish.pdf";
import ITlitepaper from "../docs/Litepaper - Italian.pdf";
import VIlitepaper from "../docs/Litepaper - Vietnam.pdf";
import GKlitepaper from "../docs/Litepaper - Greek.pdf";
import PLlitepaper from "../docs/Litepaper - Polish.pdf";
import PTlitepaper from "../docs/Litepaper - Portuguese.pdf";

import "./whitepaper.css";
import "./index.css";

import { graphql } from "gatsby";

const Wrapper = styled.div`
  overflow: hidden;
  min-height: 100vh;
  background: white;
`;

const WhitepaperPage = () => {
  const { t } = useTranslation();

  const content = [
    {
      title: t("whitepaper.modelUpdate.title"),
      date: t("whitepaper.modelUpdate.date"),
      paragraph: t("whitepaper.modelUpdate.description"),
      linkEn: ENupdate,
      linkCh: CHupdate,
      linkAr: ARupdate,
      linkJp: JPupdate,
      linkKr: KRupdate,
      linkRu: RUupdate,
      linkSp: SPupdate,
      linkFr: FRupdate,
      linkDe: DEupdate,
      linkIt: ITupdate,
      linkVi: VIupdate,
      linkGk: GKupdate,
      linkPl: PLupdate,
      linkPt: PTupdate,
      asLink: true
    },
    {
      title: t("whitepaper.modelWhitepaper.title"),
      date: t("whitepaper.modelWhitepaper.date"),
      paragraph: t("whitepaper.modelWhitepaper.description"),
      linkEn: ENwhitepaper,
      linkCh: CHwhitepaper,
      linkAr: ARwhitepaper,
      linkJp: JPwhitepaper,
      linkKr: KRwhitepaper,
      linkRu: RUwhitepaper,
      linkSp: SPwhitepaper,
      linkDe: DEwhitepaper,
      linkTr: TRwhitepaper,
      linkFr: FRwhitepaper,
      linkIt: ITwhitepaper,
      linkVi: VIwhitepaper,
      linkGk: GKwhitepaper,
      linkPl: PLwhitepaper,
      linkPt: PTwhitepaper
    },
    {
      title: t("whitepaper.litepaper.title"),
      date: t("whitepaper.litepaper.date"),
      paragraph: t("whitepaper.litepaper.description"),
      linkEn: ENlitepaper,
      linkCh: CHlitepaper,
      linkAr: ARlitepaper,
      linkJp: JPlitepaper,
      linkKr: KRlitepaper,
      linkRu: RUlitepaper,
      linkSp: SPlitepaper,
      linkFr: FRlitepaper,
      linkDe: DElitepaper,
      linkTr: TRlitepaper,
      linkIt: ITlitepaper,
      linkVi: VIlitepaper,
      linkGk: GKlitepaper,
      linkPl: PLlitepaper,
      linkPt: PTlitepaper
    }
  ];
  return (
    <ThemeProvider theme={lightTheme}>
      <MetaComponent pageName="Whitepaper" />
      <Wrapper>
        <Menu activeMenu="whitepaper" />
        <HeroWhitepaper background={heroBackgound} backgoundmb={heroBackgoundMobile} />
        <div className="paperContents">
          {content.map((item) => (
            <PaperContent content={item} key={item.title} />
          ))}
        </div>
        <Footer />
      </Wrapper>
    </ThemeProvider>
  );
};

export default WhitepaperPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
