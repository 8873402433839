import React from "react";
import EngLanguage from "./../../images/icons/enFlag-big.svg";
import ChLanguage from "./../../images/icons/chinaFlag-big.svg";
import JpLanguage from "./../../images/icons/japanFlag-big.svg";
import ArLanguage from "./../../images/icons/arabicFlag-big.svg";
import KrLanguage from "./../../images/icons/koreaFlag-big.svg";
import RuLanguage from "./../../images/icons/russianFlag-big.svg";
import TrLanguage from "./../../images/icons/turkishFlag-big.svg";
import FrLanguage from "./../../images/icons/franchFlag-big.svg";
import DELanguage from "./../../images/icons/germanyFlag-big.svg";
import ITLanguage from "./../../images/icons/italianFlag-big.svg";
import SPLanguage from "./../../images/icons/spanishFlag-big.svg";
import ViLanguage from "./../../images/icons/vietnamFlag-big.svg";
import PlLanguage from "./../../images/icons/polishFlag-big.svg";
import PtLanguage from "./../../images/icons/portugalFlag-big.svg";
import "./paperContent.css";

export default function PaperContent({ content }) {
  return (
    <section className="whitepaperContentWrap">
      <h2 className="whitepaperTitle">{content.title}</h2>
      <h3 className="date">{content.date}</h3>
      <p className="whitepaperParagraph">{content.paragraph}</p>
      <div className="download">
        <a href={content.linkEn} target="_blank" rel="noopener noreferrer" className="link">
          <button className="paperCta">
            <img src={EngLanguage} alt="English language icon" />
            English [PDF]
          </button>
        </a>
        {content.linkCh ? (
          <a href={content.linkCh} target="_blank" rel="noopener noreferrer" className="link">
            <button className="paperCta">
              <img src={ChLanguage} alt="Chinese language icon" />
              中文 [PDF]
            </button>
          </a>
        ) : (
          ""
        )}
        {content.linkAr ? (
          <a href={content.linkAr} target="_blank" rel="noopener noreferrer" className="link">
            <button className="paperCta">
              <img src={ArLanguage} alt="Arabic language icon" />
              عربي [PDF]
            </button>
          </a>
        ) : (
          ""
        )}
        {content.linkJp ? (
          <a href={content.linkJp} target="_blank" rel="noopener noreferrer" className="link">
            <button className="paperCta">
              <img src={JpLanguage} alt="Japan language icon" />
              日本語 [PDF]
            </button>
          </a>
        ) : (
          ""
        )}
        {content.linkKr ? (
          <a href={content.linkKr} target="_blank" rel="noopener noreferrer" className="link">
            <button className="paperCta">
              <img src={KrLanguage} alt="Korea language icon" />
              한국인 [PDF]
            </button>
          </a>
        ) : (
          ""
        )}
        {content.linkRu ? (
          <a href={content.linkRu} target="_blank" rel="noopener noreferrer" className="link">
            <button className="paperCta">
              <img src={RuLanguage} alt="Russian language icon" />
              русский [PDF]
            </button>
          </a>
        ) : (
          ""
        )}
        {content.linkFr ? (
          <a href={content.linkJp} target="_blank" rel="noopener noreferrer" className="link">
            <button className="paperCta">
              <img src={FrLanguage} alt="French language icon" />
              français [PDF]
            </button>
          </a>
        ) : (
          ""
        )}
        {content.linkDe ? (
          <a href={content.linkDe} target="_blank" rel="noopener noreferrer" className="link">
            <button className="paperCta">
              <img src={DELanguage} alt="Germany language icon" />
              Deutsch [PDF]
            </button>
          </a>
        ) : (
          ""
        )}
        {content.linkIt ? (
          <a href={content.linkIt} target="_blank" rel="noopener noreferrer" className="link">
            <button className="paperCta">
              <img src={ITLanguage} alt="Italiano language icon" />
              Italiano [PDF]
            </button>
          </a>
        ) : (
          ""
        )}
        {content.linkTr ? (
          <a href={content.linkTr} target="_blank" rel="noopener noreferrer" className="link">
            <button className="paperCta">
              <img src={TrLanguage} alt="turkish language icon" />
              Türkçe [PDF]
            </button>
          </a>
        ) : (
          ""
        )}
        {content.linkSp ? (
          <a href={content.linkSp} target="_blank" rel="noopener noreferrer" className="link">
            <button className="paperCta">
              <img src={SPLanguage} alt="Spanish language icon" />
              Español [PDF]
            </button>
          </a>
        ) : (
          ""
        )}
        {content.linkVi ? (
          <a href={content.linkVi} target="_blank" rel="noopener noreferrer" className="link">
            <button className="paperCta">
              <img src={ViLanguage} alt="Vietnam language icon" />
              Tiếng Việt [PDF]
            </button>
          </a>
        ) : (
          ""
        )}
        {content.linkGk ? (
          <a href={content.linkGk} target="_blank" rel="noopener noreferrer" className="link">
            <button className="paperCta">
              <img src={SPLanguage} alt="Greek language icon" />
              Ελληνικά [PDF]
            </button>
          </a>
        ) : (
          ""
        )}
        {content.linkPl ? (
          <a href={content.linkPl} target="_blank" rel="noopener noreferrer" className="link">
            <button className="paperCta">
              <img src={PlLanguage} alt="Polish language icon" />
              język polski [PDF]
            </button>
          </a>
        ) : (
          ""
        )}
        {content.linkPt ? (
          <a href={content.linkPt} target="_blank" rel="noopener noreferrer" className="link">
            <button className="paperCta">
              <img src={PtLanguage} alt="Português language icon" />
              Português [PDF]
            </button>
          </a>
        ) : (
          ""
        )}
      </div>
    </section>
  );
}
